<template>
<vx-card no-shadow :title="pageTitle">
    <template slot="actions">
        <vs-button @click="handleBack" color="dark">Back</vs-button>
    </template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveBallot)" autocomplete="nope">
            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Ballot For Date:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="BallotDate">
                        <flat-pickr class="w-full" name="BallotDate" :config="BallotDateTimeConfig" v-model="ballots.ballotDate" :disabled="launchDateDisabled" @on-change="onBallotChange" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span class="mt-3">Capacity: </span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" name="Capacity" :rules="{ regex: /^[0-9]*$/, required: true, max: 4 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" name="Capacity" v-model="ballots.capacity" autocomplete="nope" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span class="mt-3">Preference (%): </span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                    <ValidationProvider class="w-full" name="preference1" :rules="{ regex: /^[0-9]*$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full" label="Preference 1" name="preference1" @keyup="changePreference1(ballots.preference1)" v-model="ballots.preference1" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                    <ValidationProvider class="w-full" name="preference2" :rules="{ regex: /^[0-9]*$/, required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full ml-2" label="Preference 2" name="preference2" @keyup="changePreference(ballots.preference2)" v-model="ballots.preference2" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
                    <ValidationProvider class="w-full" name="other" :rules="{ required: true, max: 100 }" v-slot="{ errors }" :custom-messages="customMessages">
                        <vs-input class="w-full ml-4" label="Others" name="other" v-model="ballots.other" readonly disabled />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>Launch Date:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="LaunchDate">
                        <flat-pickr class="w-full" name="LaunchDate" :config="DateTimeConfig" v-model="ballots.startDate" :disabled="launchDateDisabled" @on-change="onStartChange" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-row class="mb-base">
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span>End Date:</span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <ValidationProvider class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages" name="endDate">
                        <flat-pickr class="w-full" name="endDate" :config="EndDateTimeConfig" v-model="ballots.endDate" />
                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                </vs-col>
            </vs-row>

            <vs-divider />

            <vs-row>
                <vs-col class="m-2" vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
                    <span></span>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
                    <vs-button class="mr-3 mb-2" @click.prevent="saveBallot">Submit</vs-button>
                </vs-col>
            </vs-row>
        </form>
    </ValidationObserver>
</vx-card>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot"

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
    customMessages
} from "../../filters/validationmessage";

export default {
    props: {
        salutation: {
            type: Array
        },
        currentBallotId: {
            type: String
        }
    },

    components: {
        flatPickr
    },

    data() {
        return {
            pageTitle: "Open A Ballot",
            customMessages,
            ballots: {},
            capacity: "",
            launchDateDisabled: false,
            BallotDateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
            },
            DateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
                //minDate: new Date(),
            },
            EndDateTimeConfig: {
                wrap: true,
                altFormat: "d-m-Y",
                altInput: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                enableTime: false,
                time_24hr: false,
                minDate: new Date(),
            },
        };
    },

    async created() {
        await this.loadBallot()
    },

    computed: {
        ballotID() {
            return this.$route.params.id;
        }
    },
    methods: {
        onBallotChange(selectedDates, dateStr, instance) {
            this.$set(this.DateTimeConfig, "maxDate", dateStr);
            this.$set(this.EndDateTimeConfig, "maxDate", dateStr);
        },
        onStartChange(selectedDates, dateStr, instance) {
            this.$set(this.EndDateTimeConfig, "minDate", dateStr);
        },
        changeRange(value) {
            this.ballots.allowLocal = value;
            if (value > 100) {
                this.$vs.notify({
                    title: "",
                    text: "Value must be less then 100",
                    color: "warning",
                    position: "top-center"
                });
            }
            this.ballots.allowNonLocal = 100 - Number(value);
        },
        changePreference1(value) {
            this.ballots.preference1 = value;
            if (value > 100) {
                this.$vs.notify({
                    title: "",
                    text: "Value must be less then 100",
                    color: "warning",
                    position: "top-center"
                });
            }

        },
        changePreference(value) {
            const sum = Number(value) + Number(this.ballots.preference1);
            this.ballots.other = 100 - sum;
            this.ballots.preference2 = value;
            if (sum > 100) {
                this.$vs.notify({
                    title: "",
                    text: "Preference must be less then 100",
                    color: "warning",
                    position: "top-center"
                });
            }
        },
        async loadBallot() {
            if (this.ballotID) {
                this.ballots = await ballotData.SearchBallotByKey(this.ballotID)
                if (this.ballots) {
                    this.pageTitle = "Edit Ballot";
                    let splits = this.ballots.preferenceRatio.split(",");
                    this.ballots.preference1 = splits[0];
                    this.ballots.preference2 = splits[1];
                    this.ballots.other = splits[2];

                    if (new Date(this.ballots.startDate) <= new Date()) {
                        this.launchDateDisabled = true;
                        this.DateTimeConfig.minDate = this.ballots.startDate;
                    }
                }
            }
        },
        handleBack() {
            this.$router.back();
        },
        async saveBallot() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }
            const numbers = Number(this.ballots.preference1) + Number(this.ballots.preference2) + Number(this.ballots.other)
            if (numbers !== 100) {
                this.$vs.notify({
                    title: "",
                    text: "Preference must be less then 100",
                    color: "warning",
                    position: "top-center"
                });
                return;
            }

            this.ballots = {
                ...this.ballots,
                preferenceRatio: this.ballots.preference1 + ',' + this.ballots.preference2 + ',' + this.ballots.other
            }

            let apiResult = await ballotData.SaveBallot(this.ballots);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
        }
    }
};
</script>
